import styled from "styled-components";
import React from "react";
import { fonts } from "theme/fonts";

export const ButtonPrimary = ({
  as,
  href,
  className,
  onClick,
  target,
  rel,
  children,
}) => {
  return (
    <Wrapper
      as={as}
      href={href}
      className={className}
      onClick={onClick}
      target={target}
      rel={rel}
    >
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.button`
  display: inline-block;
  padding: 8px 30px;
  border: 0px;
  border-radius: 5px;
  background-color: #ed1c2e;
  color: #ffffff;
  outline: 0;
  cursor: pointer;
  font-family: "Avenir_Bold";
  text-decoration: none;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 1rem;

  &:hover {
    background-color: #a6111e;
  }
`;
