import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { MaxWidthContainer } from "./MaxWidthContainer";

export const Main = ({
  heroContent,
  heading,
  subHeading,
  body,
  children,
  flexGrow,
  registration,
  preCampaign,
  voting,
}) => {
  let showMainContainer = false;
  if (subHeading || body) showMainContainer = true;

  return (
    <MainWrapper
      id="main"
      flexGrow={flexGrow}
      registration={registration}
      preCampaign={preCampaign}
      voting={voting}
    >
      {heroContent}

      {heading && (
        <HeadingWrapper id="heading">
          <HeadingContent>{ReactHtmlParser(heading)}</HeadingContent>
        </HeadingWrapper>
      )}

      {showMainContainer && (
        <BodyWrapper id="body">
          <MaxWidthContainer>
            {subHeading && (
              <Subheading>{ReactHtmlParser(subHeading)}</Subheading>
            )}
            {body && (
              <Body>
                <p>{ReactHtmlParser(body)}</p>
              </Body>
            )}
          </MaxWidthContainer>
        </BodyWrapper>
      )}

      {!!children && children}
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.flexGrow && `flex: 1;`}
  padding: 0;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    padding: 0 0px;
  }
`;

const HeadingWrapper = styled.div`
  padding: 35px 15px 0px;
`;

const HeadingContent = styled.h3`
  font-size: clamp(24px, 3vw, 32px);
  text-align: center;
  margin-bottom: 12px;
  text-transform: uppercase;
`;

const BodyWrapper = styled.div`
  padding: 0 15px 35px;
`;

const Subheading = styled.h4`
  font-size: clamp(28px, 6vw, 32px);
  margin: 0 0 16px;
  text-transform: uppercase;
`;

const Body = styled.div`
  font-size: 18px;
  text-align: center;

  p {
    margin-top: 0;
  }
`;
