import { fonts } from "./fonts";
import { MAXTheme } from "./notes";

export const theme = {
  // overrides styled-component fonts
  fonts: {
    header: `font-family: ${fonts.header};`,
    default: `font-family: ${fonts.default};`,
  },
  // custom media queries
  maxMedia: {
    small: "(min-width: 768px)",
    medium: "(min-width: 950px)",
    large: "(min-width: 1240px)",
  },
  media: {
    small: "(max-width: 767px)",
    medium: "(min-width: 768px) and (max-width: 949px)",
    large: "(min-width: 950px)",
    extraLarge: "(min-width: 1304px)",
  },
  ...MAXTheme,
};
