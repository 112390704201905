import styled, { css } from "styled-components";
import { Modal as NotesModal } from "notes";

export const Modal = ({ open, setOpen, children, hero, size, isMobile }) => {
  return (
    <ModalBackground open={open}>
      <ModalWrapper
        size={size}
        isMobile={isMobile}
        open={open}
        hero={hero}
        setOpen={setOpen}
      >
        <ModalContainer>{children}</ModalContainer>
      </ModalWrapper>
    </ModalBackground>
  );
};

// ======== MODAL ======== //

export const ModalBackground = styled.div`
  ${(props) =>
    props.open &&
    `
    background-color: rgb(0 0 0 / 85%);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const ModalWrapper = styled(NotesModal)`
  padding: 65px 40px;
  min-height: unset;
  width: 100%;
  display: flex;
  flex: 1;
  background-color: #000;
  line-height: 1.5;
  margin: 25px 0;

  ${(props) =>
    props.hero &&
    css`
      padding: 0;
      background-color: #fff;
      //  aspect-ratio: 2/1.1;
      min-height: 600px;
      border-radius: 13px;
    `}

  & > svg {
    border: 1.5px solid #fff;
    border-radius: 25px;
    padding: 5px;
    width: 28px;
    height: 28px;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.3);
    & > path {
      fill: #fff;
    }
  }
  ${(props) =>
    props.isMobile &&
    css`
      min-height: unset;
      padding: 15px !important;
      width: 90%;
      margin: 20px auto;
      & > svg {
        right: 8%;
      }
    `}

  & > svg:hover {
    background-color: #fff;

    & > path {
      fill: #000;
    }
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex: unset;
    margin: auto;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;
