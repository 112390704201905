import styled from "styled-components";
import { Radio, GridColumn } from "notes";
import { FieldMessage } from "Components";

export const RadioField = ({ onChange, value, field, error = {} }) => {
  const handleRadio = (value) => {
    onChange({ [field.name]: value });
  };
  return (
    <>
      <StyledGrid>
        {field.options?.map(({ id, title, subtitle }) => (
          <InlineRadio
            key={id}
            checked={value === title}
            onChange={() => handleRadio(title)}
            title={title}
          ></InlineRadio>
        ))}
      </StyledGrid>
      {error.status && (
        <FieldMessage
          active={value || !!error.status}
          content={error.message}
          type={error.status ? "error" : "success"}
        />
      )}
    </>
  );
};

const InlineRadio = styled(Radio)`
  margin-top: 0;
  margin-bottom: 0;
  span {
    font-size: 12px;
    padding-right: 20px;
  }
  svg {
    border: 1px solid #000000b6;
    border-radius: 3px;
    padding: 0px;
  }
  svg.lowIyQ {
    circle#inner {
      transform: scale(3);
    }
  }
  #outer {
    stroke: #fff;
    fill: #fff;
  }
  #inner {
    fill: #102b4e;
    r: 15 !important;
  }
`;
const StyledGrid = styled(GridColumn)`
  display: flex;
  flex-direction: row;
`;
