const palette = {
  white: "#ffffff",
  black: "#222222",
  action: {
    primary: "#7C60D9",
    darker: "#46367A",
    lighter: "#BCAFE6",
    lightest: "#F2EFFC",
    reverse: "#ffffff",
  },
  gray: {
    primary: "#596266",
    lighter: "#A6AEB2",
    lightest: "#E6E9EB",
    hover: "#F5F5F5",
    fill: "#FAFAFA",
  },
  purple: {
    primary: "#7C60D9",
    darker: "#46367A",
    lighter: "#BCAFE6",
    lightest: "#F2EFFC",
  },
  red: {
    primary: "#E45C52",
    darker: "#8F150B",
    lighter: "#EDB8B4",
    lightest: "#FAF1F0",
  },
  green: {
    primary: "#6DCC6A",
    lighter: "#A9DDA8",
    lightest: "#EBF5EB",
  },
  orange: {
    primary: "#FF8C00",
    lighter: "#F9BF78",
    lightest: "#FDF4E9",
  },
  yellow: {
    primary: "#FFBF00",
    lighter: "#FFEAAD",
    lightest: "#FFFAEB",
  },
  teal: {
    primary: "#39A4A1",
    darker: "#2B7A78",
    lighter: "#A7DBDA",
    lightest: "#DFEBEA",
  },
  blue: {
    lighter: "#BCDBE6",
    lightest: "#EBFAFF",
  },
};

const input = {
  backgroundColor: palette.white,
  borderColor: palette.gray.primary,
  borderRadius: "4px",
  color: palette.black,
  placeholder: {
    color: palette.gray.primary,
  },
  focus: {
    borderColor: palette.action.lighter,
    boxShadow: `0 0 4px 0 ${palette.action.lighter}`,
  },
  disabled: {
    backgroundColor: palette.gray.lightest,
    borderColor: palette.gray.lighter,
    color: palette.gray.primary,
    placeholder: {
      color: palette.gray.lighter,
    },
  },
  error: {
    backgroundColor: palette.red.lightest,
    borderColor: palette.red.primary,
  },
  select: {
    dropdown: {
      borderColor: palette.gray.primary,
      borderTopColor: palette.gray.lightest,
      backgroundColor: palette.white,
      divider: palette.gray.lightest,
    },
    open: {
      borderBottomColor: palette.gray.lightest,
    },
  },
};

const colors = {
  primary: palette.purple,
  orange: "#FF8C00",
  action: "#7C60D9",
  actionLight: "#f0eef8",
  white: "#FFF",
  green: "#6DCC6A",
  border: "#596266",
  borderLight: "#E6E8EA",
  //Form Fields
  labelTextDefault: "#222222",
  inputBg: "#ffffff",
  inputText: "#222222",
  inputPlaceholderText: "#596266",
  inputErrorBg: "#FAF1F0",
  inputErrorBorder: "#E45C52",
  disabledText: "#A6AEB2",
  disabledFill: "#E6E9EB",
  text: "#222222",
  disabledIcon: "#A6AEB2",
  selectedColor: "#6DCC6A",
  selectItemHover: "#F5F5F5",
  selectItemDivider: "#E7E9EB",
  selectBottomBorder: "#D2D3D5",
  //Buttons
  buttonPrimaryText: "#ffffff",
  buttonPrimaryBgHover: "#46367A",
  buttonPrimaryBgDisabled: "#E6E9EB",
  buttonPrimaryTextDisabled: "#A6AEB2",
  buttonToggleDisabled: "F5F5F5",
  //Links
  linkDefault: "#7C60D9",
  linkHover: "#46367A",
  linkDisabled: "#A6AEB2",
  linkDeleteDefault: "#E45C52",
  linkDeleteHover: "#8F150B",
  linkDeleteDisabled: "#A6AEB2",
  //Social Platforms
  facebook: "#4267B2",
  twitter: "#1DA1F2",
  instagram: "#E0518F",
  youtube: "#FF0000",
  spotify: "#1DB954",
  soundcloud: "#FF5500",
  bandsintown: "#00B4B3",
  pinterest: "#CA2028",
  google: "#FFFFFF",
  //Radio / Checkbox
  checkboxDisabledPath: "#A6AEB2",
  checkboxDisabledCheckedFill: "#596266",
  checkboxDisabledUncheckedFill: "#E6E9EB",
  cardAddonBorder: "#A6AEB2",
  //Show Selector
  locationTextDarkMode: "#E6E9EB",
  subheaderDefault: "#A6AEB2",
  errorMessageBorder: "#EDB8B4",
  errorMessageText: "#8F150B",
  //Tags
  approved: "#6DCC6A",
  approvedLight: "#EBF5EB",
  pending: "#F69B2C",
  pendingLight: "#FDF4E9",
  //Toolbar
  toolbarBackground: "#FFFFFF",
  toolbarBackgroundSecondary: "#FFEAAD",
  //Pill
  pillDuration: "#39A4A1",
};

export const MAXTheme = {
  colors,
  input,
  palette,
};
